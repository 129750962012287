<template>
  <div class="order-samples-index width-100">
    <Filters
      :filters="orderSamplesFilters"
      filter-key="orderSamples"
      :initial-data="filtersStatus.orderSamples"
    />
    <TableWrapper
      enable-selection
      :table-columns="orderSamplesColumns"
      :massive-actions-options="massiveActionsOptions"
      table-key="orderSamples"
      @showAnalyses="goToOrderSampleAnalyses"
    />
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import { orderSamplesColumns, orderSamplesFilters } from '@/config';
import { SAMPLE_STATUSES_DESC } from '@/config/constants';
import { ordersApi } from '@/api';
import Filters from '@/components/Filters.vue';
import TableWrapper from '@/components/TableWrapper.vue';

export default {
  name: 'OrderSamplesIndex',
  components: {
    Filters,
    TableWrapper,
  },
  data() {
    return {
      orderSamplesColumns,
      orderSamplesFilters,
      massiveActionsOptions: Object.keys(SAMPLE_STATUSES_DESC)
        .map(key => ({ label: `enums.sampleStatuses.${SAMPLE_STATUSES_DESC[key]}`, value: key })),
    };
  },
  computed: {
    ...mapGetters([
      'filtersStatus',
      'tableFilters',
    ]),
  },
  created() {
    this.parseQueryStringStatus(this.$route.query);
    this.getTableRows();
  },
  beforeRouteUpdate(to, from, next) {
    this.parseQueryStringStatus(to.query);
    this.getTableRows();
    next();
  },
  methods: {
    ...mapActions([
      'updateTableRows',
      'updateTableCount',
      'parseQueryStringStatus',
    ]),
    async getTableRows() {
      try {
        const orderSamplesIndexResp = await ordersApi.samplesIndex(
          this.$route.params.orderId,
          this.tableFilters('orderSamples'),
          this.filtersStatus.orderSamples,
        );
        const orderSamplesCountResp = await ordersApi
          .samplesCount(this.$route.params.orderId, this.filtersStatus.orderSamples);

        this.updateTableRows({ tableKey: 'orderSamples', rows: (orderSamplesIndexResp.data || []) });
        this.updateTableCount({ tableKey: 'orderSamples', count: orderSamplesCountResp.data.count });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    goToOrderSampleAnalyses(row) {
      this.$router.push({
        name: 'orderSampleAnalysesIndex',
        params: { orderId: this.$route.params.orderId, sampleId: row.id },
      });
    },
  },
};
</script>

<style lang="scss">

.order-samples-index {

  .el-table__body tr:hover > td.table-cell-status {
    @each $modifier, $map in $sample-statuses {
      &--#{$modifier} {
        filter: brightness(0.9);
        background-color: map-get($map, 'background');
      }
    }
  }

  .table-cell-status {
    @each $modifier, $map in $sample-statuses {
      &--#{$modifier} {
        background-color: map-get($map, 'background');
        color: map-get($map, 'color');
      }
    }
  }
}

</style>
